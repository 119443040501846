import React from "react";
import BodyClassName from "react-body-classname";
import Layout from "../components/layout";
import Footer from "../components/footer";
import SEO from "../components/seo";
import { useIntl, Link, navigate } from "gatsby-plugin-intl";
import { useStaticQuery, graphql } from "gatsby";
import Img from "gatsby-image";
import { connect } from "react-redux";
import Tools from "../classes/tools";

const Filosofia = ({ s }) => {
	const intl = useIntl();

	const query = useStaticQuery(
		graphql`
			{
				philosophyImage: file(relativePath: { eq: "philosophy.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				philosophyImageDesktop: file(relativePath: { eq: "philosophy-desktop.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				philosophyImage2: file(relativePath: { eq: "philosophy-02.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				philosophyImage2Desktop: file(relativePath: { eq: "philosophy-02-desktop.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				philosophyImage3: file(relativePath: { eq: "philosophy-03.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
				philosophyImage3Desktop: file(relativePath: { eq: "philosophy-03-desktop.jpg" }) {
					childImageSharp {
						fluid {
							...GatsbyImageSharpFluid
						}
					}
				}
			}
		`
	);

	const onHeaderGeoLocationChange = (data) => {
		navigate(
			`/anuncios/mode/geo/lat/${data.lat}/long/${data.long}/geo_descripcion/${data.descripcion}/orden/distancia`
		);
	};

	return (
		<>
			<BodyClassName className="philosophy">
				<Layout onHeaderGeoLocationChange={onHeaderGeoLocationChange}>
					<SEO title={Tools.convertString(s.filosofia?.title)} />
					<main className="page">
						<article className="bg-img">
							<h1 className="white-text prequel-font">{Tools.convertString(s.filosofia?.title)}</h1>
							<picture>
								<source srcSet="/img/bg/philosophy-desktop.jpg" media="(min-width: 768px)" />
								<img srcSet="/img/bg/philosophy.jpg" alt={``} />
							</picture>
							{/* <Img className="hide-md" fluid={query.philosophyImage.childImageSharp.fluid} alt={``} />
									<Img className="show-md" fluid={query.philosophyImageDesktop.childImageSharp.fluid} alt={``} /> */}
						</article>

						<article className="box location">
							<div className="wrapper-text">
								<ul className="breadcrumbs white-bg">
									<li className="link">
										<Link to="/" className="ltblue-text-02">
											Home
										</Link>
									</li>
									<li className="active">
										<p className="lightgray-text-07">{Tools.convertString(s.filosofia?.title)}</p>
									</li>
								</ul>
							</div>
							<div className="wrapper-text box white-bg">
								<h4 className="red-text prequel-font">{Tools.convertString(s.filosofia?.bloque1_titulo)}</h4>
								<div className="text">
									<p className="black-text-01">
										{Tools.convertString(s.filosofia?.bloque1_texto1)}
										<br />
										<br />
										{Tools.convertString(s.filosofia?.bloque1_texto2)}
									</p>
								</div>
							</div>
							<Img className="hide-md" fluid={query.philosophyImage2.childImageSharp.fluid} alt={``} />
							<Img className="show-md" fluid={query.philosophyImage2Desktop.childImageSharp.fluid} alt={``} />
						</article>

						<article className="about black-bg-03">
							<h5 className="box margin-auto prequel-font white-text">
								{Tools.convertString(s.filosofia?.bloque2_texto)}
							</h5>
						</article>

						<article className="about-02 white-bg">
							<div className="wrapper box">
								<img
									className="show-lg"
									src="/img/bg/philosophy-03-desktop.jpg"
									alt={Tools.convertString(s.filosofia?.title)}
								/>
								{/* <Img
											className="show-lg"
											fluid={query.philosophyImage3Desktop.childImageSharp.fluid}
											alt={Tools.convertString(s.filosofia?.title)}
										/> */}
								<div className="about-02-text">
									<h5 className="red-text prequel-font">{Tools.convertString(s.filosofia?.bloque3_titulo)}</h5>
									<h2 className="black-text-01 prequel-font">{Tools.convertString(s.filosofia?.bloque3_subtitulo)}</h2>
									<p className="black-text-01">{Tools.convertString(s.filosofia?.bloque3_texto)}</p>
								</div>
							</div>
							{/* <img
										className="hide-lg"
										src="/img/bg/philosophy-03.jpg"
										alt={Tools.convertString(s.filosofia?.title)}
									/> */}
							<Img className="hide-lg" fluid={query.philosophyImage3.childImageSharp.fluid} alt={``} />
						</article>
						<Footer />
					</main>
				</Layout>
			</BodyClassName>
		</>
	);
};

const mapStateToProps = (state) => {
	return {
		s: state.app.boot.strings,
	};
};

export default connect(mapStateToProps)(Filosofia);
